import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import { HTMLContent } from '../../components/Content';
import BackgroundImage from '../../components/BackgroundImage';
import { ImageOverlay } from '../../utils';
import PageHelmet from '../../components/PageHelmet';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const NewsPostTemplate = ({
  content,
  heroImageObject,
  title,
  description,
  helmet,
}) => {
  const pageUrl = typeof window === 'undefined' ? null : window.location.href;
  return (
    <div className='page-container container content blog-post-container'>
      {helmet || ''}
      <PageHelmet
        seoObject={{ metaTitle: title, metaDescription: description }}
      />
      <div className='full-width-padding'>
        <BackgroundImage
          classes={'page-header-image'}
          imageInfo={heroImageObject.image}>
          <div className='image-title'>
            <hr />
            {title}
            <div className='news-social-sharing'>
              <FacebookShareButton
                url={pageUrl}
                quote={title}
                className='news-share-button'>
                <FacebookIcon size={40} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={pageUrl}
                quote={title}
                className='news-share-button'>
                <TwitterIcon size={40} round />
              </TwitterShareButton>
              <LinkedinShareButton
                url={pageUrl}
                quote={title}
                className='news-share-button'>
                <LinkedinIcon size={40} round />
              </LinkedinShareButton>
              <EmailShareButton
                url={pageUrl}
                quote={title}
                className='news-share-button'>
                <EmailIcon size={40} round />
              </EmailShareButton>
            </div>
          </div>
          <ImageOverlay overlay={heroImageObject.overlay} />
        </BackgroundImage>
      </div>
      <div className='container content'>
        <div className='columns'>
          <div className='column is-10 is-offset-1'>
            <HTMLContent content={content} className='blog-content-new' />
          </div>
        </div>
      </div>
    </div>
  );
};
const NewsPost = ({ data, location }) => {
  const { post } = data;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <NewsPostTemplate
        location={location}
        content={post.html}
        seoObject={post.frontmatter.seoObject}
        contentComponent={HTMLContent}
        heroImageObject={post.frontmatter.heroImageObject}
        description={post.frontmatter.description}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        helmet={
          <MetaComponent
            title={`${post.frontmatter.title}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${post.frontmatter.description}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${post.frontmatter.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

NewsPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default NewsPost;

export const pageQuery = graphql`
  query NewsPostByIDJP($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        heroImageObject {
          overlay
          image
        }
      }
    }
  }
`;
